import React from "react";
import { Link } from "react-router-dom";
import Auto from "../../assets/images/401AutoLogoNew.png";
import { HOME_COMPONENT } from "../../util";

const Header = (props) => {
  const { confirmComponent, onComponentChange } = props;
  return (
    <div className="header-camp ">
      <div className=" wrap ">
        <div className="wrapWidth">
          <div className="logo ">
            <Link to="/">
              <img
                src={Auto}
                className="contain-cover w-[300px] max-md:w-[250px] "
              />
            </Link>
          </div>
        </div>
        <div className="flex flex-col head-bottom ">
          <div className="wrapWidth">
            <p className="desc">Compare Car Loans From 25+ Lenders</p>
            <p className="desc">Rates From 0% - No Money Down</p>
            <p className="desc">
              Receive Instant Offers - Guaranteed Approvals
            </p>
          </div>
        </div>
        <div className="heading wrapWidth">
          <h2 className="desc">Buy Your Vehicle 100% Online</h2>
        </div>
        <div className="flex text-left wrapWidth">
          <ul className="gap-4 max-md:gap-7 bud-menu grid lg:grid-cols-4 max-md:grid-cols-2">
            <li className="bud-list">
              <button
                className={`bud-item ${
                  confirmComponent.includes(HOME_COMPONENT.BUDGET)
                    ? "bud-item-selected"
                    : ""
                }`}
                onClick={() => onComponentChange(HOME_COMPONENT.BUDGET)}
              >
                Budget
                <span
                  className={`bud-span ${
                    confirmComponent.includes(HOME_COMPONENT.BUDGET)
                      ? "bud-span-selected"
                      : ""
                  }`}
                >
                  1
                </span>
              </button>
            </li>
            <li className="bud-list">
              <button
                className={`bud-item ${
                  confirmComponent.includes(HOME_COMPONENT.VEHICLE)
                    ? "bud-item-selected"
                    : ""
                }`}
                onClick={() => onComponentChange(HOME_COMPONENT.VEHICLE)}
              >
                Vehicle
                <span
                  className={`bud-span ${
                    confirmComponent.includes(HOME_COMPONENT.VEHICLE)
                      ? "bud-span-selected"
                      : ""
                  }`}
                >
                  2
                </span>
              </button>
            </li>
            <li className="bud-list">
              <button
                className={`bud-item ${
                  confirmComponent.includes(HOME_COMPONENT.LOCATION)
                    ? "bud-item-selected"
                    : ""
                }`}
                onClick={() => onComponentChange(HOME_COMPONENT.LOCATION)}
              >
                Location
                <span
                  className={`bud-span ${
                    confirmComponent.includes(HOME_COMPONENT.LOCATION)
                      ? "bud-span-selected"
                      : ""
                  }`}
                >
                  3
                </span>
              </button>
            </li>
            <li className="bud-list">
              <button
                className={`bud-item ${
                  confirmComponent.includes(HOME_COMPONENT.OFFER)
                    ? "bud-item-selected"
                    : ""
                }`}
                onClick={() => onComponentChange(HOME_COMPONENT.OFFER)}
              >
                Get Offers
                <span
                  className={`bud-span ${
                    confirmComponent.includes(HOME_COMPONENT.OFFER)
                      ? "bud-span-selected"
                      : ""
                  }`}
                >
                  4
                </span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
