import React, { useState, useMemo } from "react";
import emailjs from "@emailjs/browser";
import Header from "../../components/header";
import Wrapper from "../../routes/Wrapper";
import Budget from "../../components/budget/Budget";
import Vehicle from "../../components/vehicle/Vehicle";
import Location from "../../components/location/location";
import Offer from "../../components/offer/offer";
import Thanks from "../../components/thanks/Thanks";
import Footer from "../../components/footer";
import {
  HOME_COMPONENT,
  DEFAULT_BUDGET,
  DEFAULT_LOCATION,
  DEFAULT_VEHICLE,
  DEFAULT_INVENTORY,
  DEFAULT_OFFER,
} from "../../util";

const Main = () => {
  const [currentComponent, setCurrentComponent] = useState(
    HOME_COMPONENT.BUDGET
  );
  const [confirmComponent, setConfirmComponent] = useState([
    HOME_COMPONENT.BUDGET,
  ]);
  const [data, setData] = useState({
    budget: DEFAULT_BUDGET,
    vehicle: DEFAULT_VEHICLE,
    inventory: DEFAULT_INVENTORY,
    location: DEFAULT_LOCATION,
    offer: DEFAULT_OFFER,
  });

  const componentChangeHandle = useMemo(
    () => (selectedComponent) => {
      if (confirmComponent.includes(selectedComponent)) {
        setCurrentComponent(selectedComponent);
      }
    },
    [confirmComponent]
  );

  const pickHandle = useMemo(
    () => (currentBudget) => {
      setData({
        ...data,
        budget: currentBudget,
      });
      setConfirmComponent([...confirmComponent, HOME_COMPONENT.VEHICLE]);
      setCurrentComponent(HOME_COMPONENT.VEHICLE);
    },
    [confirmComponent, data]
  );

  const approvalHandle = useMemo(
    () => (approvalData) => {
      setData({
        ...data,
        vehicle: approvalData.vehicle,
        inventory: approvalData.inventory,
      });
      setConfirmComponent([...confirmComponent, HOME_COMPONENT.LOCATION]);
      setCurrentComponent(HOME_COMPONENT.LOCATION);
    },
    [confirmComponent, data]
  );

  const vehicleClickHandle = useMemo(
    () => (location) => {
      setData({
        ...data,
        location,
      });
      setConfirmComponent([...confirmComponent, HOME_COMPONENT.OFFER]);
      setCurrentComponent(HOME_COMPONENT.OFFER);
    },
    [confirmComponent, data]
  );

  const offerDataChangeHandle = useMemo(
    () => (offerData) => {
      setData({
        ...data,
        offer: offerData,
      });
    },
    [data]
  );

  const thanksClickHandle = useMemo(
    () => () => {
      setConfirmComponent([...confirmComponent, HOME_COMPONENT.THANKS]);
      setCurrentComponent(HOME_COMPONENT.THANKS);

      emailjs
        .send(
          "service_6vvyv32",
          "template_npe96eg",
          {
            ...(data.location || {}),
            ...(data.offer || {}),
            budget: data.budget,
            vehicle: data.vehicle,
            inventory: data.inventory.toDateString(),
          },
          "r0_ooDJmbVoS_DqS4"
        )
        .then(
          (response) => {
            console.log("SUCCESS!", response.status, response.text);
          },
          (err) => {
            console.log("FAILED...", err);
          }
        );
    },
    [confirmComponent, data]
  );

  return (
    <Wrapper>
      {currentComponent !== HOME_COMPONENT.THANKS && (
        <Header
          confirmComponent={confirmComponent}
          onComponentChange={componentChangeHandle}
        />
      )}
      <div className="">
        <div className="wrap wrapWidth flex flex-col">
          {currentComponent === HOME_COMPONENT.VEHICLE ? (
            <Vehicle
              selectedVehicle={data.vehicle}
              selectedInventory={data.inventory}
              onApprovalClick={approvalHandle}
            />
          ) : currentComponent === HOME_COMPONENT.LOCATION ? (
            <Location
              budget={data.budget}
              vehicle={data.vehicle}
              location={data.location}
              onVehicleClick={vehicleClickHandle}
            />
          ) : currentComponent === HOME_COMPONENT.OFFER ? (
            <Offer
              budget={data.budget}
              vehicle={data.vehicle}
              location={data.location}
              offer={data.offer}
              onOfferDataChange={offerDataChangeHandle}
              onThanksClick={thanksClickHandle}
            />
          ) : currentComponent === HOME_COMPONENT.THANKS ? (
            <Thanks {...data} />
          ) : (
            <Budget selectedBudget={data.budget} onPickClick={pickHandle} />
          )}
        </div>
      </div>
      <Footer />
    </Wrapper>
  );
};

export default Main;
