import React, { useState, useMemo, useCallback } from "react";
import { OFFER_ERROR, OFFER_TYPE } from "../../util";

const Offer = (props) => {
  const { vehicle, offer, onThanksClick, onOfferDataChange } = props;
  const {
    birthday,
    type,
    pay,
    source,
    years,
    months,
    income,
    incomeMonths,
    bring,
  } = offer || {};

  const sources = [
    "Employed Full or Part time",
    "Self Employed",
    "Student not working",
    "Retired or Disability",
    "Homemaker or Unemployed",
    "Other",
  ];

  const [formErrors, setFormErrors] = useState({
    birthday: "",
    type: "",
    pay: "",
    source: "",
    lived: "",
    income: "",
    bring: "",
  });

  const birthdayChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        birthday: value ? "" : OFFER_ERROR.FIELD_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        birthday: value,
      });
    },
    [offer, formErrors]
  );

  const rentSelected = useMemo(
    () => () => {
      setFormErrors({
        ...formErrors,
        type: "",
      });
      onOfferDataChange({
        ...(offer || {}),
        type: OFFER_TYPE.RENT,
      });
    },
    [offer, formErrors]
  );

  const ownSelected = useMemo(
    () => () => {
      setFormErrors({
        ...formErrors,
        type: "",
      });
      onOfferDataChange({
        ...(offer || {}),
        type: OFFER_TYPE.OWN,
      });
    },
    [offer, formErrors]
  );

  const payChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        pay: value
          ? /(0?[1-9]|1[012])/.test(value)
            ? ""
            : OFFER_ERROR.NUMBER_REQUIRED
          : OFFER_ERROR.FIELD_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        pay: value,
      });
    },
    [offer, formErrors]
  );

  const yearsChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        lived:
          months || value
            ? months || /(0?[1-9]|1[012])/.test(value)
              ? ""
              : OFFER_ERROR.NUMBER_REQUIRED
            : OFFER_ERROR.ONE_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        years: value,
      });
    },
    [months, offer, formErrors]
  );

  const monthsChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        lived:
          years || value
            ? years || /(0?[1-9]|1[012])/.test(value)
              ? ""
              : OFFER_ERROR.NUMBER_REQUIRED
            : OFFER_ERROR.ONE_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        months: value,
      });
    },
    [years, offer, formErrors]
  );

  const sourceChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        source: value ? "" : OFFER_ERROR.FIELD_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        source: value,
      });
    },
    [offer, formErrors]
  );

  const incomeChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        income:
          incomeMonths || value
            ? incomeMonths || /(0?[1-9]|1[012])/.test(value)
              ? ""
              : OFFER_ERROR.NUMBER_REQUIRED
            : OFFER_ERROR.ONE_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        income: value,
      });
    },
    [incomeMonths, offer, formErrors]
  );

  const incomeMonthsChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        income:
          income || value
            ? income || /(0?[1-9]|1[012])/.test(value)
              ? ""
              : OFFER_ERROR.NUMBER_REQUIRED
            : OFFER_ERROR.ONE_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        incomeMonths: value,
      });
    },
    [income, offer, formErrors]
  );

  const bringChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        bring: value
          ? /(0?[1-9]|1[012])/.test(value)
            ? ""
            : OFFER_ERROR.NUMBER_REQUIRED
          : OFFER_ERROR.FIELD_REQUIRED,
      });
      onOfferDataChange({
        ...(offer || {}),
        bring: value,
      });
    },
    [offer, formErrors]
  );

  const validate = useCallback(() => {
    let noError = true;
    const errors = {
      ...formErrors,
    };

    if (!birthday || !birthday.trim()) {
      errors.birthday = OFFER_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!type) {
      errors.type = OFFER_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!pay || !pay.trim()) {
      errors.pay = OFFER_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!source || !source.trim()) {
      errors.source = OFFER_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!years || !years.trim()) {
      if (!months || !months.trim()) {
        errors.lived = OFFER_ERROR.FIELD_REQUIRED;
        noError = false;
      }
    }

    if (!months || !months.trim()) {
      if (!years || !years.trim()) {
        errors.lived = OFFER_ERROR.FIELD_REQUIRED;
        noError = false;
      }
    }

    if (!income || !income.trim()) {
      if (!incomeMonths || !incomeMonths.trim()) {
        errors.income = OFFER_ERROR.FIELD_REQUIRED;
        noError = false;
      }
    }

    if (!incomeMonths || !incomeMonths.trim()) {
      if (!income || !income.trim()) {
        errors.income = OFFER_ERROR.FIELD_REQUIRED;
        noError = false;
      }
    }

    if (!bring || !bring.trim()) {
      errors.bring = OFFER_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    setFormErrors(errors);

    return noError;
  }, [birthday, type, source, pay, years, months, income, incomeMonths, bring]);

  const handleSubmit = useMemo(
    () => (e) => {
      e.preventDefault();

      if (validate()) {
        onThanksClick({
          birthday,
          type,
          years,
          months,
          source,
          income,
          incomeMonths,
          bring,
        });
      }
    },
    [birthday, type, years, months, source, income, incomeMonths, bring]
  );

  return (
    <div className="offer wrapWidth flex flex-col gap-4">
      <div className="off-wrap">
        <h2 className="heading">Just need a little more info</h2>
        <h1 className="main-heading">You're 30 seconds away</h1>
        <p className="desc">
          We will send your offers as soon as you complete this final step.
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="off-form">
          <label className="off-lbl">
            What is your birthday?
            <input
              type="date"
              value={birthday}
              onChange={birthdayChange}
              className="off-input"
            />
          </label>
          <p className="error-msg">{formErrors.birthday}</p>
          <div>
            <label className="off-lbl">
              Do you rent or own?
              <div className="flex gap-2 my-2">
                <p
                  className={`rent ${
                    type === OFFER_TYPE.RENT ? "rent-selected" : ""
                  }`}
                  onClick={rentSelected}
                >
                  Rent
                </p>
                <p
                  className={`own ${
                    type === OFFER_TYPE.OWN ? "own-selected" : ""
                  }`}
                  onClick={ownSelected}
                >
                  Own
                </p>
              </div>
              <p className="error-msg">{formErrors.type}</p>
            </label>
          </div>

          <label className="off-lbl">
            How much do you pay per month for rent?
            <input
              type="text"
              value={pay}
              onChange={payChange}
              className="off-input"
            />
          </label>
          <p className="error-msg">{formErrors.pay}</p>

          <div className="">
            <h3>How long have you lived there?</h3>
            <div className="grid lg:grid-cols-2 gap-2">
              <input
                type="text"
                value={years}
                onChange={yearsChange}
                placeholder="Years"
                className="off-input"
              />

              <input
                type="text"
                value={months}
                onChange={monthsChange}
                placeholder="Months"
                className="off-input"
              />
              <p className="error-msg">{formErrors.lived}</p>
            </div>
          </div>

          <div>
            <label className="off-lbl">
              What is your primary source of income
            </label>
            <select
              className="off-select"
              value={source}
              onChange={sourceChange}
            >
              <option value=""></option>
              {sources.map((source) => (
                <option key={source} value={source}>
                  {source}
                </option>
              ))}
            </select>
            <p className="error-msg">{formErrors.source}</p>
          </div>

          <div className="">
            <h3>How long have you received this income?</h3>
            <div className="grid lg:grid-cols-2 gap-2">
              <input
                type="text"
                value={income}
                onChange={incomeChange}
                placeholder="Years"
                className="off-input"
              />
              <input
                type="text"
                value={incomeMonths}
                onChange={incomeMonthsChange}
                placeholder="Months"
                className="off-input"
              />
              <p className="error-msg">{formErrors.income}</p>
            </div>
          </div>

          <div className="flex flex-col ">
            <h2 className="my-2">How much do you bring in monthly?</h2>
            <h3 className="off-sm">Include all sources before deductions</h3>
          </div>
          <input
            type="text"
            value={bring}
            onChange={bringChange}
            className="off-input"
          />
          <p className="error-msg">{formErrors.bring}</p>
          <div>
            <h3 className="off-sm my-4">
              By clicking on the finalize button, I confirm my agreement to 401
              Auto Financing's Terms of Use | Privacy Policy and my consent to
              401 Auto Financing obtaining credit reports about me to facilitate
              my application for a car loan.
            </h3>
          </div>
          <button type="submit" className="off-btn">
            Finalize My {vehicle.toUpperCase()} Approval Now
          </button>
        </div>
      </form>
    </div>
  );
};

export default Offer;
