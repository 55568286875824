import React from "react";

const Thanks = (props) => {
  const {
    location: { email, firstName, lastName, phoneNumber },
  } = props;

  return (
    <div className=" thanks">
      <div className="flex justify-center  mt-10 ">
        <img
          className="contain-cover w-[300px] max-md:w-[250px]"
          src={require("../../assets/images/logo.png")}
          alt=""
        />
      </div>
      <h2 className="thanks-heading">Thank You!</h2>
      <h2 className="thanks-desc">
        A complete copy of your application has been assigned to a 401 Auto
        Financing Credit Specialist who will contact you shortly.
      </h2>
      <p className="data-desc ">
        {"First Name = " + firstName}, {"Last Name = " + lastName},{" "}
        {"Email = " + email}, {"Phone = " + phoneNumber}
      </p>
    </div>
  );
};

export default Thanks;
