import React, { useMemo, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";

const testimonials = [
  {
    car_Img: require("../../assets/images/MarkR.jpg"),
    client_Img: require("../../assets/images/client-1.jpg"),
    client_name: "Mark R",
    testimonial:
      "Meer’s crew got me my dream Honda Accord, upgraded to a 2020 Toyota RAV4 in 18 months. Credit's up, and SUV life feels awesome.",
  },
  {
    car_Img: require("../../assets/images/UchennaM.jpg"),
    client_Img: require("../../assets/images/client-2.jpg"),
    client_name: "Uchenna M",
    testimonial:
      "New to Canada, Meer’s newcomer program got me a reliable Honda Civic. A month in, credit's already up. Grateful for this solid start.",
  },
  {
    car_Img: require("../../assets/images/MelissaS.jpg"),
    client_Img: require("../../assets/images/client-3.jpg"),
    client_name: "Melissa S",
    testimonial:
      "Needed a car quick, Meer’s team got me a slick Nissan Rogue. In 15 months, upgraded to a spacious 2021 Nissan Pathfinder.",
  },
  {
    car_Img: require("../../assets/images/JonathanR.jpg"),
    client_Img: require("../../assets/images/client-4.jpeg"),
    client_name: "Jonathan W",
    testimonial:
      "Needed a reliable truck, Meer’s team delivered a 2017 Ford F-150. After 16 months, traded up for a 2020 GMC Sierra. Lower payments, more truck power, and credit score went up.",
  },
  {
    car_Img: require("../../assets/images/PatrickD.jpg"),
    client_Img: require("../../assets/images/client-5.jpg"),
    client_name: "Patrick D",
    testimonial:
      "Started with a gas guzzler, Meer’s team got me into a fuel-efficient 2018 Toyota Prius. In 14 months, switched to a hybrid 2020 Toyota RAV4, paying less for gas. Credit's up 167 points after all my consecutive bi-weekly payments added up.",
  },
  {
    car_Img: require("../../assets/images/AdemolaM.jpeg"),
    client_Img: require("../../assets/images/client-6.jpg"),
    client_name: "Ademola M",
    testimonial:
      "Impressed with Meer’s impact on my credit and ride. Got solid approval and education on financing. Grateful for the help.",
  },
  {
    car_Img: require("../../assets/images/CatherineR.jpg"),
    client_Img: require("../../assets/images/client-7.jpeg"),
    client_name: "Catherine R",
    testimonial:
      "Contacted Approved By Meer’s team through a referral, and the person who referred got $500. After 13 months, switched my 2014 Chevy Equinox for a 2020 Chevrolet Equinox Hybrid with better terms.",
  },
  {
    car_Img: require("../../assets/images/AdaoraO.jpg"),
    client_Img: require("../../assets/images/client-8.jpg"),
    client_name: "Adaora O",
    testimonial:
      "Good experience with Approved by Meer. In 16 months, traded my 2013 Lexus ES for a 2020 Lexus RX Hybrid, paying less for gas. Their team was well-organized and answered all my questions.",
  },
  {
    car_Img: require("../../assets/images/BrandyD.jpg"),
    client_Img: require("../../assets/images/client-9.jpg"),
    client_name: "Brandy D",
    testimonial:
      "Meer’s magic didn't stop with my first truck. In 14 months, traded my robust GMC Sierra for a more fuel-efficient GMC Terrain, saving more on gas and payments.",
  },
  {
    car_Img: require("../../assets/images/YousefH.jpg"),
    client_Img: require("../../assets/images/client-10.jpg"),
    client_name: "Yousef H",
    testimonial:
      "Moved to Canada from Iraq, couldn't get approved for a vehicle. Contacted Approved By Meer’s team, and they got me in a 2021 Mitsubishi RVR in under 14 days. Swift and grateful. Will refer more customers to get that $500 referral fee.",
  },
];

const features = [
  { label: "Bad credit", img: require("../../assets/images/checkmark.png") },
  { label: "No credit", img: require("../../assets/images/checkmark.png") },
  { label: "Collections", img: require("../../assets/images/checkmark.png") },
  { label: "$0 Down", img: require("../../assets/images/checkmark.png") },
  { label: "Bankruptcy", img: require("../../assets/images/checkmark.png") },
  { label: "Late payments", img: require("../../assets/images/checkmark.png") },
  { label: "Repossessions", img: require("../../assets/images/checkmark.png") },
  { label: "Low payment", img: require("../../assets/images/checkmark.png") },
];

const Budget = (props) => {
  const { selectedBudget, onPickClick } = props;
  const [budget, setBudget] = useState(selectedBudget);

  const pickHandle = useMemo(
    () => () => {
      onPickClick(budget);
    },
    [budget]
  );

  return (
    <div className="budget">
      <div className="wrap1">
        <h2 className="heading">What's Your Budget</h2>
        <h1 className="main-heading">${budget}/Month</h1>
        <div className="loading-bar">
          <input
            className="slider mb-4"
            type="range"
            min="150"
            max="800"
            step="10"
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />
          <div className="flex justify-between ml-4 max-md:ml-0 mt-2">
            <p className="desc">150</p>
            <p className=" desc ml-11 max-md:ml-5">500</p>
            <p className="desc mr-3 max-md:mr-0">800</p>
          </div>
        </div>
        <p className="desc">No obligation. No commitments. Only FREE offers.</p>
      </div>
      <div className="wrap2">
        <h2 className="heading">Thousands of vehicles in your area</h2>
      </div>
      <div className=" slider">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="">
                <div className="flex flex-col">
                  <img
                    src={testimonial.car_Img}
                    className="contain-cover  h-[50px] max-md:h-0 rounded-[10px]"
                  />
                  <div className="slider-desc ">
                    <div className="flex justify-center items-center gap-6">
                      <img src={testimonial.client_Img} className="desc-img " />
                      <p className="text-center">{testimonial.client_name}</p>
                    </div>
                    <div className="flex justify-center mt-2">
                      <p className="text-[10px] px-6 ">
                        {testimonial.testimonial}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="bud-box">
        <ul className="grid lg:grid-cols-3 max-md:grid-cols-2">
          {features.map((feature, index) => (
            <React.Fragment key={index}>
              <div className="flex logo ">
                <div>
                  <img
                    src={feature.img}
                    alt="Checkmark"
                    className="w-[20px] h-[20px] max-md:w-[12px] max-md:h-[12px]"
                  />
                </div>
                <li className="desc">{feature.label}</li>
              </div>
            </React.Fragment>
          ))}
        </ul>
      </div>
      <button className="bud-btn" onClick={pickHandle}>
        Pick your vehicle now
      </button>
    </div>
  );
};

export default Budget;
