import React from "react";
import { useSelector } from "react-redux";
import Header from "../components/header";
import Footer from "../components/footer";

const Wrapper = ({ children }) => {
  return (
    <div className={`dashboard-page flex flex-col`}>
      <div className="pages-block flex flex-col relative h-full">
        <section className="mb-14">{children}</section>
      </div>
    </div>
  );
};
export default Wrapper;
