import React, { useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { VEHICLE } from "../../util";

const Vehicle = (props) => {
  const { selectedVehicle, selectedInventory, onApprovalClick } = props;

  const [vehicle, setVehicle] = useState(selectedVehicle);

  const [error, setError] = useState(false);
  const [inventory, setInventory] = useState(selectedInventory);

  const onCarClick = useMemo(
    () => () => {
      setVehicle(VEHICLE.CAR);
      setError(false);
    },
    []
  );

  const onTruckClick = useMemo(
    () => () => {
      setVehicle(VEHICLE.TRUCK);
      setError(false);
    },
    []
  );

  const onSuvClick = useMemo(
    () => () => {
      setVehicle(VEHICLE.SUV);
      setError(false);
    },
    []
  );

  const onVanClick = useMemo(
    () => () => {
      setVehicle(VEHICLE.VAN);
      setError(false);
    },
    []
  );

  const approvalHandle = useMemo(
    () => () => {
      if (vehicle) {
        onApprovalClick({ vehicle, inventory });
      } else {
        setError(true);
      }
    },
    [vehicle, inventory]
  );

  return (
    <div className="wrapWidth vehicle ">
      <h2 className="text-center veh-heading ">What are you looking for?</h2>
      <div className="grid lg:grid-cols-2 max-md:grid-cols-2 gap-5 mt-8">
        <div
          className={`veh-box ${
            vehicle === VEHICLE.CAR ? "veh-box-selected" : ""
          }`}
          onClick={onCarClick}
        >
          <img
            src={require("../../assets/images/car.png")}
            className=""
            alt=""
          />
          <p>I want a Car</p>
        </div>
        <div
          className={`veh-box ${
            vehicle === VEHICLE.TRUCK ? "veh-box-selected" : ""
          }`}
          onClick={onTruckClick}
        >
          <img
            src={require("../../assets/images/truck.png")}
            className=""
            alt=""
          />
          <p>I want a Truck</p>
        </div>
        <div
          className={`veh-box ${
            vehicle === VEHICLE.SUV ? "veh-box-selected" : ""
          }`}
          onClick={onSuvClick}
        >
          <img
            src={require("../../assets/images/suv.png")}
            className=""
            alt=""
          />
          <p>I want a SUV</p>
        </div>
        <div
          className={`veh-box ${
            vehicle === VEHICLE.VAN ? "veh-box-selected" : ""
          }`}
          onClick={onVanClick}
        >
          <img
            src={require("../../assets/images/van.png")}
            className=""
            alt=""
          />
          <p>I want a Van</p>
        </div>
      </div>
      <div className="h-[24px]">
        {" "}
        {error && <div className="error">This field is required.</div>}
      </div>
      <p className="text-center text-[12px] mb-3">
        Inventory updated{" "}
        <DatePicker
          selected={inventory}
          onChange={(date) => setInventory(date)}
          dateFormat="d MMM yyyy"
          className="datepicker"
        />
      </p>
      <button className="veh-btn" onClick={approvalHandle}>
        Get $0 Down Approval Offers Today!
      </button>
    </div>
  );
};

export default Vehicle;
