export const HOME_COMPONENT = {
  BUDGET: "budget",
  VEHICLE: "vehicle",
  LOCATION: "location",
  OFFER: "offer",
  THANKS: "thanks",
};

export const DEFAULT_BUDGET = 150;
export const DEFAULT_VEHICLE = "";
export const DEFAULT_INVENTORY = new Date();
export const DEFAULT_LOCATION = null;
export const DEFAULT_OFFER = null;

export const VEHICLE = {
  CAR: "car",
  TRUCK: "truck",
  SUV: "suv",
  VAN: "van",
};

export const LOCATION_ERROR = {
  FIELD_REQUIRED: "This field is required",
  EMAIL_VALID: "Please enter a valid email address.",
  PHONE_VALID: "Please specify a valid phone number",
  VALID_REQUIRED: "Invalid format",
};

export const OFFER_ERROR = {
  FIELD_REQUIRED: "This field is required",
  NUMBER_REQUIRED: "Please enter a valid number.",
  ONE_REQUIRED: "Please fill at least 1 of these fields.",
};

export const OFFER_TYPE = {
  RENT: "rent",
  OWN: "own",
};

export const PHONE_LENGTH = 10;
