import React, { useState, useMemo, useCallback } from "react";
import { LOCATION_ERROR, PHONE_LENGTH } from "../../util";
// import emailjs from "@emailjs/browser";
const Location = (props) => {
  const { budget, vehicle, location, onVehicleClick } = props;
  const {
    streetName: selectedStreetName,
    city: selectedCity,
    province: selectedProvince,
    postalCode: selectedPostalCode,
    country: selectedCountry,
    email: selectedEmail,
    firstName: selectedFirstName,
    lastName: selectedLastName,
    phoneNumber: selectedPhoneNumber,
  } = location || {};
  const [streetName, setStreetName] = useState(selectedStreetName);
  const [city, setCity] = useState(selectedCity);
  const [province, setProvinceChange] = useState(selectedProvince);
  const [postalCode, setPostalChange] = useState(selectedPostalCode);
  const [country, setCountry] = useState(selectedCountry);

  const [email, setEmail] = useState(selectedEmail);
  const [firstName, setFirstName] = useState(selectedFirstName);
  const [lastName, setLastName] = useState(selectedLastName);
  const [phoneNumber, setPhoneNumber] = useState(selectedPhoneNumber);

  const [formErrors, setFormErrors] = useState({
    streetName: "",
    city: "",
    email: "",
    phoneNumber: "",
    province: "",
    postalCode: "",
    country: "",
  });

  const streetNameChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        streetName: value ? "" : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setStreetName(value);
    },
    [formErrors]
  );

  const cityChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        city: value
          ? /^[a-zA-Z]{2,4}/.test(value)
            ? ""
            : LOCATION_ERROR.VALID_REQUIRED
          : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setCity(value);
    },
    [formErrors]
  );

  const phoneNumberChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        phoneNumber: value
          ? value.length === PHONE_LENGTH
            ? ""
            : LOCATION_ERROR.PHONE_VALID
          : LOCATION_ERROR.FIELD_REQUIRED,
      });

      setPhoneNumber(value);
    },
    [formErrors]
  );

  const emailChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        email: value
          ? /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
            ? ""
            : LOCATION_ERROR.EMAIL_VALID
          : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setEmail(value);
    },
    [formErrors]
  );

  const provinceChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        province: value ? "" : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setProvinceChange(value);
    },
    [formErrors]
  );

  const postalChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        postal: value ? "" : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setPostalChange(value);
    },
    [formErrors]
  );

  const countryChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        country: value
          ? /^[a-zA-Z]{2,4}/.test(value)
            ? ""
            : LOCATION_ERROR.VALID_REQUIRED
          : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setCountry(value);
    },
    [formErrors]
  );

  const fristNameChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        firstName: value
          ? /^[a-zA-Z]{2,4}/.test(value)
            ? ""
            : LOCATION_ERROR.VALID_REQUIRED
          : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setFirstName(value);
    },
    [formErrors]
  );

  const lastNameChange = useMemo(
    () => (e) => {
      const value = e.target.value;
      setFormErrors({
        ...formErrors,
        lastName: value
          ? /^[a-zA-Z]*$/.test(value)
            ? ""
            : LOCATION_ERROR.VALID_REQUIRED
          : LOCATION_ERROR.FIELD_REQUIRED,
      });
      setLastName(value);
    },
    [formErrors]
  );

  const provinces = [
    "AB",
    "BC",
    "MB",
    "NB",
    "NL",
    "NS",
    "ON",
    "PE",
    "QB",
    "SK",
    "YT",
  ];

  const validate = useCallback(() => {
    let noError = true;
    const errors = {
      ...formErrors,
    };
    if (!streetName || !streetName.trim()) {
      errors.streetName = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!city || !city.trim()) {
      errors.city = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!province || !province.trim()) {
      errors.province = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!postalCode || !postalCode.trim()) {
      errors.postalCode = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!country || !country.trim()) {
      errors.country = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!email || !email.trim()) {
      errors.email = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      errors.email = LOCATION_ERROR.EMAIL_VALID;
      noError = false;
    }

    if (!firstName || !firstName.trim()) {
      errors.firstName = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!lastName || !lastName.trim()) {
      errors.lastName = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    }

    if (!phoneNumber || !phoneNumber.trim()) {
      errors.phoneNumber = LOCATION_ERROR.FIELD_REQUIRED;
      noError = false;
    } else if (phoneNumber.length !== PHONE_LENGTH) {
      errors.phoneNumber = LOCATION_ERROR.PHONE_VALID;
    }

    setFormErrors(errors);

    return noError;
  }, [
    streetName,
    city,
    postalCode,
    province,
    country,
    firstName,
    lastName,
    email,
    phoneNumber,
    formErrors,
  ]);

  const handleSubmit = useMemo(
    () => (e) => {
      e.preventDefault();

      if (validate()) {
        onVehicleClick({
          streetName,
          city,
          province,
          postalCode,
          country,
          email,
          firstName,
          lastName,
          phoneNumber,
        });
      }
    },
    [
      streetName,
      city,
      postalCode,
      province,
      country,
      firstName,
      lastName,
      email,
      phoneNumber,
    ]
  );

  return (
    <div className="location wrapWidth">
      <div className="loc-wrap">
        <h2 className="haeding">You're Almost Done!</h2>
        <h1 className="main-heading">Where are you located?</h1>
        <p className="desc">
          Let us know your address so we can find the best{" "}
          {vehicle.toUpperCase()}s in your area that match your budget of $
          {budget}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="loc-form">
          <input
            type="text"
            value={streetName}
            onChange={streetNameChange}
            placeholder="Start typing your address"
            className="loc-input-main"
          />
          <p className="error-msg">{formErrors.streetName}</p>
        </div>
        <div className="loc-form">
          <label className="loc-lbl">
            Street Name
            <input
              type="text"
              value={streetName}
              onChange={streetNameChange}
              placeholder=""
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.streetName}</p>

          <label className="loc-lbl">
            City
            <input
              type="text"
              value={city}
              onChange={cityChange}
              placeholder=""
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.city}</p>

          <label className="loc-lbl">Province</label>
          <select
            className="loc-select"
            value={province}
            onChange={provinceChange}
          >
            <option value="">Unknown</option>
            {provinces.map((province) => (
              <option key={province} value={province}>
                {province}
              </option>
            ))}
          </select>
          <p className="error-msg">{formErrors.province}</p>

          <label className="loc-lbl">
            Postal Code
            <input
              type="text"
              value={postalCode}
              onChange={postalChange}
              placeholder=""
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.postalCode}</p>

          <label className="loc-lbl">
            Country
            <input
              type="text"
              value={country}
              onChange={countryChange}
              placeholder=""
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.country}</p>
        </div>

        <div className="flex flex-col loc-form">
          <h3 className="">Where should we send your approval offers?</h3>

          <label className="loc-lbl">
            Email Address
            <input
              type="email"
              value={email}
              onChange={emailChange}
              placeholder="Email address"
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.email}</p>

          <div className="">
            <h3 className="">What's your name?</h3>

            <div className="grid lg:grid-cols-2 gap-2">
              <div className="flex flex-col">
                <input
                  type="text"
                  value={firstName}
                  onChange={fristNameChange}
                  placeholder="First name"
                  className="loc-input"
                />
                <p className="error-msg">
                  {formErrors.firstName || formErrors.lastName}
                </p>
              </div>
              <div className="flex flex-col">
                <input
                  type="text"
                  value={lastName}
                  onChange={lastNameChange}
                  placeholder="Last name"
                  className="loc-input"
                />
              </div>
            </div>
          </div>

          <label className="loc-lbl">
            Phone Number
            <input
              type="tel"
              value={phoneNumber}
              onChange={phoneNumberChange}
              placeholder="(--)--- ----"
              className="loc-input"
            />
          </label>
          <p className="error-msg">{formErrors.phoneNumber}</p>
        </div>

        <button className="loc-btn" type="submit">
          Pick your vehicle now
        </button>
      </form>
    </div>
  );
};

export default Location;
