const Footer = () => {
  return (
    <div className="footer-comp flex flex-col ">
      <div className=" wrap  flex jc aic">
        <div className="wrapWidth grid lg:grid-cols-4 max-md:grid-cols-2  ">
          <p className="desc">Terms & Conditions</p>
          <p className="desc">Privacy Policy</p>
          <p className="desc">Email: meersautosolutions@gmail.com</p>
          <p className="desc">Phone: 9054073377</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
